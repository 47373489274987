import React from 'react';
import {
  Typography, Tabs, Tab, Box, Paper, AppBar, Divider, withStyles,
} from '@material-ui/core';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import { HelpCircle } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { withPermissionsChecker } from 'wrappers';
import { Alert } from '@material-ui/lab';
import Loader from '../LoadingScreen';

/**
 * the props a receive are
 * is required: config = [
    { label: 'Tab 1', component: <TabBody id={id} /> },
    { label: 'Tab 2', component: <TabBody id="1" /> },
  ];
  * is optional: selected = 1
 */

const styles = (theme => ({
  tab: {
    '& .MuiTab-wrapper': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  tabSize: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: window.screen.width - 30,
    },
  },
}));

class TabControl extends React.Component {
  constructor(props) {
    super(props);
    const { selected, reference } = props;
    this.state = {
      selectedTab: selected,
      visible: true,
      hasTab: false,
    };
    if (reference) {
      reference.current = {
        setState: newState => this.setState(newState),
      };
    }
  }

  componentDidMount() {
    const {
      config,
      selected,
      keepSelected,
      location,
    } = this.props;
    const { pathname } = location;
    if (keepSelected) {
      const cookieSelected = Cookie.get('selectedTab');
      if (this.checkJson(cookieSelected)) {
        const parsed = JSON.parse(cookieSelected);
        const { selected: cSelected, view } = parsed;
        if (view === pathname) {
          this.setState({ selectedTab: cSelected });
        }
      }
    }
    if (config[selected].invisible) {
      // eslint-disable-next-line react/no-did-update-set-state
      let bool = false;
      config.forEach((item, index) => {
        if (bool) {
          return;
        }
        if (!item.invisible) {
          this.setState({
            selectedTab: index,
            visible: true,
          });
          bool = true;
        }
      });
      if (!bool) {
        this.setState({
          visible: false,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      loading, config, selected,
    } = this.props;
    if (loading !== prevProps.loading) {
      if (config[selected].invisible) {
        // eslint-disable-next-line react/no-did-update-set-state
        let bool = false;
        config.forEach((item, index) => {
          if (bool) {
            return;
          }
          if (!item.invisible) {
            this.setState({
              selectedTab: index,
              visible: true,
            });
            bool = true;
          }
        });
        if (!bool) {
          this.setState({
            visible: false,
          });
        }
      }
    }
    if (selected !== prevProps.selected) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedTab: selected });
    }
    if (prevProps.config[selected].invisible !== config[selected].invisible) {
      // eslint-disable-next-line react/no-did-update-set-state
      let bool = false;
      config.forEach((item, index) => {
        if (bool) {
          return;
        }
        if (!item.invisible) {
          this.setState({
            selectedTab: index,
            visible: true,
          });
          bool = true;
        }
      });
      if (!bool) {
        this.setState({
          visible: false,
        });
      }
    }
  }

  checkJson = (something) => {
    try {
      JSON.parse(something);
      return true;
    } catch (e) {
      return false;
    }
  }

  // change the selected tab in the state
  handleChange = (event, nextSelectedTab) => {
    const { selectedTab } = this.state;
    const { setSelected, keepSelected, location } = this.props;
    const { pathname } = location;
    clearTimeout(this.timer);
    if (selectedTab !== nextSelectedTab) {
      this.setState({ selectedTab: nextSelectedTab });

      if (keepSelected) {
        Cookie.set(
          'selectedTab',
          JSON.stringify({ selected: nextSelectedTab, view: pathname }),
        );
      }

      if (setSelected) {
        setSelected(nextSelectedTab);
      }
    }
  };

  render() {
    const {
      selectedTab,
      visible,
      hasTab,
    } = this.state;
    const {
      config,
      loading,
      classes,
      checkAccess,
    } = this.props;
    const tabConfig = config[selectedTab] ? config[selectedTab] : null;
    const { component: Component, wrap = true } = tabConfig;
    return loading ? <Loader /> : (
      <>
        {visible && (
          <>
            <AppBar position="static" color="inherit" className={classes.tabSize}>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={selectedTab}
                onChange={this.handleChange}
                variant="scrollable"
              >
                {config.map((element, index) => {
                  if (!element.invisible) {
                    if (element.permission) {
                      if (checkAccess(element.permission)) {
                        if (!hasTab) {
                          this.setState({
                            hasTab: true,
                          });
                        }
                        return (
                          <Tab
                            key={`tab${String(index)}`}
                            label={element.label}
                            icon={element.actions && Object.keys(element.actions).length > 0
                              ? (
                                <HelpCircle
                                  style={{ marginTop: 5, marginLeft: 10 }}
                                  onMouseEnter={
                                    e => element.actions.openPopover(e, element.actions.message)
                                  }
                                  onMouseLeave={element.actions.closePopover}
                                  onMouseDown={
                                    e => element.actions.openPopover(e, element.actions.message)
                                  }
                                />
                              )
                              : (<></>)}
                            className={classes.tab}
                            value={index}
                            style={{ textTransform: 'none', backgroundColor: element.color || '' }}
                            disabled={element.disabled ? element.disabled : false}
                          />
                        );
                      }
                      return ([]);
                    }
                    if (!hasTab) {
                      this.setState({
                        hasTab: true,
                      });
                    }
                    return (
                      <Tab
                        key={`tab${String(index)}`}
                        label={element.label}
                        icon={element.actions && Object.keys(element.actions).length > 0
                          ? (
                            <HelpCircle
                              style={{ marginTop: 5, marginLeft: 10 }}
                              onMouseEnter={
                                e => element.actions.openPopover(e, element.actions.message)
                              }
                              onMouseLeave={element.actions.closePopover}
                              onMouseDown={
                                e => element.actions.openPopover(e, element.actions.message)
                              }
                            />
                          )
                          : (<></>)}
                        className={classes.tab}
                        value={index}
                        style={{ textTransform: 'none', backgroundColor: element.color || '' }}
                        disabled={element.disabled ? element.disabled : false}
                      />
                    );
                  }
                  return ([]);
                })}
              </Tabs>
            </AppBar>
            {/* eslint-disable-next-line */}
            {hasTab ? (
              wrap ? (
                <Paper>
                  <Divider />
                  <Typography
                    component="div"
                    role="tabpanel"
                  >
                    <Box p={3}>{Component}</Box>
                  </Typography>
                </Paper>
              ) : Component
            ) : (
              <Paper style={{ padding: 10 }}>
                <Divider />
                <Alert severity="info">No posee permisos para ningun tab</Alert>
              </Paper>
            )}
          </>
        )}
      </>
    );
  }
}

TabControl.propTypes = {
  config: PropTypes.oneOfType([PropTypes.array]).isRequired,
  selected: PropTypes.number,
  loading: PropTypes.bool,
  setSelected: PropTypes.func,
  keepSelected: PropTypes.bool,
  classes: PropTypes.oneOfType([PropTypes.object]),
  location: PropTypes.oneOfType([PropTypes.object]),
  reference: PropTypes.oneOfType([PropTypes.object]),
  checkAccess: PropTypes.func,
};

TabControl.defaultProps = {
  selected: 0,
  keepSelected: false,
};

export default withRouter(withStyles(styles)(withPermissionsChecker(TabControl)));
