import React from 'react';
import { red, green } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import { Cancel, CheckCircle } from '@material-ui/icons';

function Check({
  activo = false,
  onClickTrue,
  onClickFalse,
  style,
}) {
  return activo
    ? (
      <CheckCircle
        style={{ color: green[500], fontSize: 35, ...style }}
        onClick={onClickTrue}
      />
    )
    : (
      <Cancel
        style={{ color: red[500], fontSize: 35, ...style }}
        onClick={onClickFalse}
      />
    );
}

Check.propTypes = {
  // Dependiendo del valor pinta un check de correcto o de error
  activo: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  onClickTrue: PropTypes.func,
  onClickFalse: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object]),
};

export default Check;
