import React, { Component } from 'react';
import PropTypes from 'prop-types';
import endPoints from 'endPoints/endPoints';
import {
  EstadoChip,
  LabelLink,
  Table,
} from 'components';
import { withApi, withNotification } from 'wrappers';

const labelLinkUsuario = {
  usuario: '/app/seguridad/usuarios/edit',
  usuarioPermissions: 'seguridad:usuarios:update',
};

class StateHistoryList extends Component {
  constructor(props) {
    super(props);
    this.endPoint = endPoints.general.maestros.stateHistory;
  }

  render() {
    const {
      modelo, modeloId, field, filtersTable = true,
    } = this.props;
    const columns = [
      {
        name: 'estadoAnterior',
        label: 'Estado anterior',
        component: row => <EstadoChip estado={row.estadoAnterior || 'SIN ESTADO'} />,
      },
      {
        name: 'estadoNuevo',
        label: 'Estado nuevo',
        component: row => <EstadoChip estado={row.estadoNuevo} />,
      },
      {
        name: 'nombreResponsable',
        label: 'Cambio realizado por',
        type: 'autocomplete',
        extra: {
          url: endPoints.hrm.maestros.empleados.autocomplete,
          requestParams: { filter: true, withName: true },
        },
        component: row => (
          <LabelLink
            labelName={row.nombreResponsable}
            labelUrl={`${labelLinkUsuario.usuario}/${row.usuarioId}`}
            targetType="_blank"
            permission={[labelLinkUsuario.usuarioPermissions]}
          />
        ),
      },
      { name: 'custom_properties.comments', label: 'Comentarios' },
      {
        name: 'fechaCambioEstado',
        label: 'Fecha de cambio',
        type: 'date-time',
      },
    ];
    return (
      <div>
        <Table
          serverSideUrl={this.endPoint.base}
          serverSideData={{ modeloId, modelo, field }}
          columns={columns}
          filtersTable={filtersTable}
        />
      </div>
    );
  }
}

StateHistoryList.defaultProps = {
  field: 'estado',
};

StateHistoryList.propTypes = {
  modeloId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  modelo: PropTypes.string.isRequired,
  field: PropTypes.string,
  filtersTable: PropTypes.bool,
};

export default withApi(withNotification(StateHistoryList));
