const PEDIDOS = 'App\\Modules\\Venta\\Models\\Pedido';
const PREPEDIDOS = 'App\\Modules\\Rpdental\\Models\\Prepedido';
const DESPACHOS = 'App\\Modules\\Wms\\Models\\Despacho';
const HOJA_RUTA = 'App\\Modules\\Wms\\Models\\HojaRuta';
const PROGSOPORTE = 'App\\Modules\\Rpdental\\Models\\Programacion';
const PROGPICKING = 'App\\Modules\\Wms\\Models\\ProgramacionPickingExistencias';
const RECOLECCIONES = 'App\\Modules\\Wms\\Models\\Recoleccion';
const REPGASTO = 'App\\Modules\\Rpdental\\Models\\ReporteGasto';
const SIR = 'App\\Modules\\Compra\\Models\\Sir';
const TOMA_INVENTARIO = 'App\\Modules\\Inventario\\Models\\TomaInventario';
const INVENTARIO = 'App\\Modules\\Inventario\\Models\\Inventario';
const ORDEN_COMPRA = 'App\\Modules\\Compra\\Models\\OrdenCompra';
const TRASLADOS = 'App\\Modules\\Inventario\\Models\\Traslado';
const EMBARQUE = 'App\\Modules\\Wms\\Models\\Embarque';
const MANTENIMIENTO = 'App\\Modules\\Inventario\\Models\\Mantenimiento';
const PREINSPECCION = 'App\\Modules\\Compra\\Models\\Preinspeccion';
const GASTOS_IMPORTACION = 'App\\Modules\\Compra\\Models\\GastoImportacion';
const RECEPCION = 'App\\Modules\\Wms\\Models\\Recepcion';
const SOLICITUD = 'App\\Modules\\Pqrsf\\Models\\Request';

export default {
  PEDIDOS,
  PREPEDIDOS,
  DESPACHOS,
  HOJA_RUTA,
  PROGSOPORTE,
  PROGPICKING,
  RECOLECCIONES,
  REPGASTO,
  SIR,
  TOMA_INVENTARIO,
  INVENTARIO,
  ORDEN_COMPRA,
  TRASLADOS,
  EMBARQUE,
  MANTENIMIENTO,
  PREINSPECCION,
  GASTOS_IMPORTACION,
  RECEPCION,
  SOLICITUD,
};
