import React, { useRef, useState } from 'react';
import {
  HelpCircle, Inbox, Calendar, ChevronRight, Edit3,
} from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import {
  Dialog,
  Tooltip,
  IconButton,
  Popover,
  makeStyles,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import SugerenciaForm from './Formularios/SugerenciaForm';
import AgendarDemostracionForm from './Formularios/AgendarDemostracionForm';
import EnviarPreguntaForm from './Formularios/EnviarPreguntaForm';

const useStyles = makeStyles({
  popover: {
    width: 350,
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
});

const CentroAyuda = () => {
  const classes = useStyles();
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [openSugerencias, setOpenSugerencias] = useState(false);
  const [openEnviarPreguntas, setOpenEnviarPreguntas] = useState(false);
  const [openAgendarDemostraciones, setOpenAgendarDemostracion] = useState(false);
  const handlePopover = () => setOpen(!open);

  const handleModalSugerencias = () => setOpenSugerencias(!openSugerencias);

  const handleModalEnviarPregunta = () => setOpenEnviarPreguntas(!openEnviarPreguntas);

  const
    handleModalAgendarDemostracion = () => setOpenAgendarDemostracion(!openAgendarDemostraciones);

  return (
    <>
      <Tooltip title="Centro de Ayuda">
        <IconButton color="inherit" onClick={handlePopover} ref={ref}>
          <HelpCircle />
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handlePopover}
        open={open}
      >
        <Box p={2}>
          <Typography variant="h4">
            Centro de Ayuda
          </Typography>
          <Divider className={classes.divider} />
          <List disablePadding>
            <ListItem
              className={classes.listItem}
              dense
              divider
              onClick={() => {
                handleModalSugerencias();
                handlePopover();
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <Inbox />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Sugerencias" secondary="Enviar sugerencia" />
              <ListItemSecondaryAction>
                <IconButton
                  component={RouterLink}
                  to="/app/centrodeayuda/sugerencias"
                  onClick={handlePopover}
                >
                  <ChevronRight />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              className={classes.listItem}
              dense
              divider
              onClick={() => {
                handleModalAgendarDemostracion();
                handlePopover();
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <Calendar />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Agendar demostración" secondary="Agendar" />
              <ListItemSecondaryAction>
                <IconButton
                  component={RouterLink}
                  to="/app/centrodeayuda/agendardemostracion"
                  onClick={handlePopover}
                >
                  <ChevronRight />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              className={classes.listItem}
              dense
              divider
              component={RouterLink}
              to="/app/centrodeayuda/preguntasfrecuentes"
            >
              <ListItemAvatar>
                <Avatar>
                  <HelpCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Preguntas Frecuentes" />
              <ListItemSecondaryAction>
                <IconButton
                  component={RouterLink}
                  to="/app/centrodeayuda/preguntasfrecuentes"
                  onClick={handlePopover}
                >
                  <ChevronRight />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              className={classes.listItem}
              dense
              divider
              component={RouterLink}
              to="/app/centrodeayuda/enviarpreguntas"
              onClick={() => {
                handleModalEnviarPregunta();
                handlePopover();
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <Edit3 />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Enviar preguntas"
              />
              <ListItemSecondaryAction>
                <IconButton
                  component={RouterLink}
                  to="/app/centrodeayuda/enviarpreguntas"
                  onClick={handlePopover}
                >
                  <ChevronRight />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Box style={{ marginTop: 15 }}>
            <Typography variant="caption">
              Puedes escribirnos a happy@360-erp.com
              y te contactaremos en cuanto estemos disponibles.
            </Typography>
          </Box>
        </Box>
      </Popover>
      <Dialog
        open={openSugerencias}
        onClose={handleModalSugerencias}
        fullWidth
        maxWidth="sm"
      >
        <SugerenciaForm
          onClose={handleModalSugerencias}
        />
      </Dialog>
      <Dialog
        open={openAgendarDemostraciones}
        onClose={handleModalAgendarDemostracion}
        fullWidth
        maxWidth="sm"
      >
        <AgendarDemostracionForm
          open={openAgendarDemostraciones}
          onClose={handleModalAgendarDemostracion}
        />
      </Dialog>
      <EnviarPreguntaForm
        open={openEnviarPreguntas}
        onClose={handleModalEnviarPregunta}
      />
    </>
  );
};

export default CentroAyuda;
