import React, {
  forwardRef,
  useEffect, useCallback,
} from 'react';
import {
  Container, makeStyles, Typography, Grid,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import track from 'utils/analytics';
import { formatter } from 'constantes/constants';
import Header from './Breadcrumbs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
}));

const PageGeneral = forwardRef(({
  title,
  breadcrumbs,
  children,
  topLabel,
  className,
  ...rest
}, ref) => {
  const location = useLocation();
  const classes = useStyles();
  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div
      ref={ref}
      {...rest}
      className={classes.root}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container
        maxWidth={false}
      >
        <Header breadcrumbs={breadcrumbs} />
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.title}
        >
          {title}
        </Typography>
        <Grid container item justifycontent="flex-end" style={{ marginBottom: 20, paddingRight: 65 }}>
          {topLabel
            && topLabel.map((row, index) => (
              <React.Fragment key={`Fragment${row.title}`}>
                <Grid item key={`Grid${row.title}`} style={{ textAlign: 'center' }}>
                  <Typography variant="h3" gutterBottom display="inline">
                    {row.title}
                  </Typography>
                  <Typography variant="h2" align={row.align || 'left'}>
                    {(row.type === 'number' ? `$ ${formatter.format(row.label)}` : row.label)}
                  </Typography>
                </Grid>
                {(topLabel.length - index) > 1
                  && (<Grid key={`Line${row.title}`} style={{ backgroundColor: 'gray', width: 1, margin: 5 }} />)}
              </React.Fragment>
            ))}
        </Grid>
        {children}
      </Container>
    </div>
  );
});

PageGeneral.displayName = 'PageGeneral';

PageGeneral.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
  })).isRequired,
  topLabel: PropTypes.oneOfType([PropTypes.array]),
};

export default PageGeneral;
